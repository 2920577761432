import * as React from "react"
import Footer from "../components/footer"
import Header from "../components/header"
import * as styles from "./recipients.module.scss"
import Layout from "../components/layout"

const RecipientBlurb = ({ recipient }) => {
  return (
    <div className={styles.recipientBox}>
      <div className={styles.recipientTextContainer}>
        <h3 className={styles.recipientName}>{recipient.name}</h3>
        <span className={styles.recipientSchool}>{recipient.school}</span>
        <p className={styles.recipientBlurb}>{recipient.blurb}</p>
      </div>
      <img className={styles.recipientImg} src={recipient.img} />
    </div>
  )
}

/*
TODOs
- update top of page blurb
- update individual recipient cards
*/

const recipients = {
  2020: [
    {
      name: "Anastasia Dai",
      img: "/recipients/2020/adai.png",
      school: "UNC Chapel Hill ‘24",
      blurb:
        "Anastasia is passionate about mental health and LGBTQ issues in Asian American communities. She plans to study biomedical engineering and explore the ethics of consumerism.",
    },
    {
      name: "Ivan Emilio Sanchez III",
      img: "/recipients/2020/isanchez.png",
      school: "UCLA ‘24",
      blurb:
        "Ivan grew up in a half-Salvadorian, half-Filipino household. He has been actively engaged in criminology and Mock Trial, with plans to battle discrimination from the courtroom.",
    },
    {
      name: "Natsumi Burde",
      img: "/recipients/2020/nburde.png",
      school: "University of Washington ‘24",
      blurb:
        "Natsumi founded a high school club dedicated to creating space to talk about personal identity. She is studying Neuroscience and is interested in studying the science behind discrimination.",
    },
    {
      name: "Sezim Minbaeva",
      img: "/recipients/2020/sminbaeva.png",
      school: "University of Texas, Austin ‘24",
      blurb:
        "Sezim was born in Kyrgyzstan, speaks Russian at home with her single mother, and is studying pre-med in hopes of opening a private clinic back in Kyrgyzstan.",
    },
    {
      name: "Daniel Chen",
      img: "/recipients/2020/dchen.png",
      school: "University of Texas, Austin ‘24",
      blurb:
        "Daniel is an avid wrestler, collector of narratives, and an aspiring legal scholar who wants to advance the role of Asian Americans through the court.",
    },
  ],
  2021: [
    {
      name: "Sian Salazar",
      img: "/recipients/2021/ssalazar.png",
      school: "University of Southern California ‘25",
      blurb:
        "Sian will be attending the University of Southern California double majoring in Global Health Studies and Political Science, with an intent to go on the pre-law track. As the daughter of Thai and Filipino parents, she has spent the last couple of years elevating AAPI voices within her community and integrating authors of color into her school's English curriculum with Diversify Granada.",
    },
    {
      name: "Kara Chu",
      img: "/recipients/2021/kchu.png",
      school: "University of California, Los Angeles ‘25",
      blurb:
        "Kara is passionate about highlighting and sharing untold AAPI stories. She dedicates her time to preserving Japanese American farming history, honoring the legacies of JA soldiers during WWII, and advocating for social  justice in solidarity with all underrepresented communities.",
    },
    {
      name: "Kathleen Hoang",
      img: "/recipients/2021/khoang.png",
      school: "University of Pennsylvania ‘25",
      blurb:
        "As the child of a Vietnamese refugee, Kathleen is passionate about the socioeconomic advancement of not only Asian Americans, but all people of color, and will be studying international relations and political science at the University of Pennsylvania to further her efforts of dismantling oppressive systems.",
    },
    {
      name: "Jiepeng (Jackson) Deng",
      img: "/recipients/2021/jdeng.png",
      school: "University of California, Berkeley ‘25",
      blurb:
        "Jiepeng will be attending University of California, Berkeley and intends to major in Psychology or Business Administration. Jackson immigrated to the US from China at age 9. In college, he wants to participate in a program to continue serving his community in hopes of opening his own non-profit in the future.",
    },
    {
      name: "Jenny Nguyen",
      img: "/recipients/2021/jnguyen.png",
      school: "Stanford University ‘25",
      blurb:
        "Jenny will be attending Stanford University and majoring in Human Biology and Bioengineering. She has worked with the Mental Health Association advocating for health equity and the Leadership Enterprise for a Diverse America (LEDA).",
    },
    {
      name: "Lynn Nguyen",
      img: "/recipients/2021/lnguyen.png",
      school: "CSU East Bay ‘25",
      blurb:
        "Lynn will be attending CSU East Bay for a BFA in Illustration. They identify as a non-binary Vietnamese artist and are interested in creating more representation relating to LGBTQ youth mental health in the Asian community in media as a character designer. They have been active in the community facilitating and planning programs discussing these problems while advocating and promoting API youth leadership.",
    },
    {
      name: "Kelly Huang",
      img: "/recipients/2021/khuang.png",
      school: "University of Southern California ‘25",
      blurb:
        'As a first-generation student, Kelly strives to educate the "modern-day confused citizen" through social activism, civic engagement, and foreign exchange. She is studying business administration on the pre-law track in hopes of building solidarity within the Asian community and beyond.',
    },
  ],
  2022: [
    {
      name: "Alessandra Mohar (She/Her)",
      img: "/recipients/2022/amohar.png",
      school: "Stanford ‘26",
      blurb:
        "Alessandra will be attending Stanford University and majoring in Political Science with a minor in Film & Media Studies to pursue her passion for storytelling. She has worked with many community organizations to promote community wellness, youth leadership, and art.",
    },
    {
      name: "Matthew Wong (They/Them)",
      img: "/recipients/2022/mwong.png",
      school: "University of Southern California ‘26",
      blurb:
        "Matthew will be attending the University of Southern California and intends to double major in Asian-American Studies and Communications. They have worked alongside a variety of community organizations in Oakland to uplift Asian-American youth through art activism. They hope to continue their advocacy work by pursuing a doctorate and returning to Oakland as an educator.",
    },
    {
      name: "Joseph Pham (He/Him)",
      img: "/recipients/2022/jpham.png",
      school: "Rice University '26",
      blurb:
        "Joseph will be attending Rice University studying Political Science and Economics with a concentration on Healthcare Policy and Healthcare Economics. He aspires to create policies that make healthcare more accessible and affordable for underserved and marginalized communities, and hopes to continue fighting against anti-Asian hate crimes and bring light to the health disparities within the Asian-American community.",
    },
    {
      name: "Fine Tuitupou (She/Her)",
      img: "/recipients/2022/ftuitupou.png",
      school: "University of California, Berkeley ‘25",
      blurb:
        "Fine will be majoring in Ethnic Studies and transferring into a four-year institution next year. Having grown up in low-income neighborhoods she witnessed the disparities in not only higher education but in the justice system when it comes to Pacific Islanders. This has galvanized her to become a civil rights attorney and continue the activism she began at her community college.",
    },
    {
      name: "Laichia Vang (She/They)",
      img: "/recipients/2022/lvang.png",
      school: "University of Minnesota-Twin Cities ‘27",
      blurb:
        "This fall Laichia will be attending the University of Minnesota-Twin Cities. She intending to major in BIS Individualized Studies with concentrations of Political Science, Sociology, and Racial Justice in Urban Schooling with a minor in Asian American Studies.",
    },
  ],
  2023: [
    {
      name: "Janessa Reyes (She/Her)",
      img: "/recipients/2023/jreyes.png",
      school: "UC Berkeley ‘27",
      blurb:
        "Janessa will be attending UC Berkeley in the fall. She is a San Francisco native and a leader in the API community at her school. She hopes to continue this advocacy and leadership while in college while studying economics. She wants to give back to her community of the Tenderloin and the Mission district to serve and uplift underrepresented communities of color.",
    },
    {
      name: "Aria Hossain (She/They)",
      img: "/recipients/2023/ahossain.png",
      school: "Macaulay Honors College at City College of New York ‘27",
      blurb:
        "Aria will be attending the City College of New York as a Macaulay Scholar and intends to major in Biology with a minor in Creative Writing. Aria is passionate about biomedical research and neuroscience. They also organize for immigration, educational reform and gender-justice with local community-based organizations. During college, Aria hopes to create high school and college-access programs for immigrant youth in NYC.",
    },
    {
      name: "William Cao (He/Him)",
      img: "/recipients/2023/wcao.png",
      school: "UCLA ‘27",
      blurb:
        "William is an incoming freshman at UCLA, where he plans to study Political Science and Economics. During the pandemic, he developed a passion for politics and launched a youth initiative aimed at helping students get more involved with current events and journalism. In his free time, he enjoys baking, discovering new music, and going on walks around the city.",
    },
    {
      name: "Jaylyn Cha (She/Her)",
      img: "/recipients/2023/jcha.png",
      school: "Princeton ‘27",
      blurb:
        "Jaylyn is passionate about mental health. Having experienced the effects of it’s stigma firsthand, her mission is to change the conversations in her community. She plans to study psychology and continue advocating for Asian Americans.",
    },
    {
      name: "Shan Villanueva (She/Her)",
      img: "/recipients/2023/svillanueva.png",
      school: "UCLA ‘27",
      blurb:
        "Shan will be attending University of California, Irvine in the fall to study Social Policy and Public Works and continue her passion in helping out with the community and student government. There she wishes to continue her passion in elevating AAPI voices and helping unite Filipino American individuals.",
    },
  ],
  2024: [
    {
      name: "Jinyu Xu",
      img: "/recipients/2024/jxu.png",
      school: "Cornell University  ‘28",
      blurb:
        "Jinyu will be attending Cornell University in the fall, where she plans to study Global & Public Health Sciences on the pre-med track. She is from Brooklyn, New York, and is passionate about issues impacting the AAPI community in NYC -- from gentrification to addressing xenophobia. She hopes to expand her reach in Asian American activism through clubs and student-led organizations on campus.",
    },
    {
      name: "Ishrat Islam",
      img: "/recipients/2024/iislam.png",
      school: "Barnard College ‘28",
      blurb:
        "Ishrat will be attending Barnard College, where she plans to major in Biology with a minor in Psychology. She aims to bridge the gap between modern and traditional medicine in underdeveloped countries. Committed to advocacy, she seeks to empower Muslim and South Asian women in her community and beyond. Ishrat aspires to create inclusive environments where everyone feels valued, regardless of race, class, or gender.",
    },
    {
      name: "Rich Wu",
      img: "/recipients/2024/rwu.png",
      school: "USC ‘28",
      blurb:
        "Rich Wu is a incoming freshman at the University of Southern California as an engineering major. Although Rich’s academic interests align in the maths and sciences, Rich continues to have a strong connection with his Asian-American roots. Growing up in San Francisco’s Chinatown, Rich’s affinity for his neighborhood led him to become a community activist and volunteer, advocating for the rights of immigrant workers and AAPI voices during the COVID pandemic. Rich’s commitment to bettering the AAPI community led him to represent as the youth representative for a local community organization, speaking at state conferences and youth panels on behalf of AAPI youth.",
    },
    {
      name: "Sarah Wang",
      img: "/recipients/2024/swang.png",
      school: "Washington University in St. Louis ‘28",
      blurb:
        "Sarah Wang, who is poised to begin her first year at Washington University in St. Louis, advocates for youth mental health initiatives as the president at her high school's Students Against Destructive Decisions chapter and is active in local politics within her East Side St. Paul neighborhood to increase civic engagement and voting participation. Upon completing her education, she is determined to create summer program opportunities tailored to underserved AAPI high school students in Minnesota to foster their academic and professional growth.",
    },
    {
      name: "Sabrina Zheng",
      img: "/recipients/2024/szheng.png",
      school: "Yale University ‘28",
      blurb:
        "Sabrina will be attending Yale University and intends to double major in computer science and sociology. At Dear Asian Youth (DAY), she helps implement organization policies and published content to be inclusive of underrepresented and intersectional Asian identities, while supporting DAY chapters in the midwest. On her journey as a social justice advocate, Sabrina hopes to use data to highlight the impacts of social issues and inequities.",
    },
  ],
}
class Recipients extends React.Component {
  constructor(props) {
    super(props)
    this.state = { year: 2024 }
    this.toggleYear = this.toggleYear.bind(this)
  }
  toggleYear(year) {
    this.setState(prevState => ({
      year: year,
    }))
  }
  render() {
    return (
      <Layout title="Scholarship Recipients | Foundation 649">
        <Header />
        <div className={styles.recipientsHero}>
          <h2>Scholarship Recipients</h2>
          <p>
            We are tremendously excited and humbled to support our cohorts of
            scholarship recipients. Each one of them shows incredible potential
            and promise as a future AAPI leader.
          </p>
          <div className={styles.toggle}>
            <span
              className={`${styles.yearToggle} ${this.state.year === 2024 ? styles.active : ""
                }`}
              onClick={() => this.toggleYear(2024)}
            >
              2024 Recipients
            </span>
            <span
              className={`${styles.yearToggle} ${this.state.year === 2023 ? styles.active : ""
                }`}
              onClick={() => this.toggleYear(2023)}
            >
              2023 Recipients
            </span>
            <span
              className={`${styles.yearToggle} ${this.state.year === 2022 ? styles.active : ""
                }`}
              onClick={() => this.toggleYear(2022)}
            >
              2022 Recipients
            </span>
            <span
              className={`${styles.yearToggle} ${this.state.year === 2021 ? styles.active : ""
                }`}
              onClick={() => this.toggleYear(2021)}
            >
              2021 Recipients
            </span>
            {/* <span
              className={`${styles.yearToggle} ${this.state.year === 2020 ? styles.active : ""
                }`}
              onClick={() => this.toggleYear(2020)}
            >
              2020 Recipients
            </span> */}
          </div>
        </div>
        <div className={styles.recipients}>
          {recipients[this.state.year].map((value, index) => {
            return <RecipientBlurb key={index} recipient={value} />
          })}
        </div>
        <Footer />
      </Layout>
    )
  }
}

export default Recipients
