import * as React from "react"
import logo from "../../static/logo-lockup.png"
import * as styles from "./footer.module.scss"
import { Link } from "gatsby"
import { FacebookIcon, InstaIcon } from "./social"

export default function Footer() {
  return (
    <div className={styles.footerWrapper}>
      <div className={styles.footer}>
        <Link to="/" className={styles.logoContainer}>
          <img className={styles.logo} src={logo} alt="" />
        </Link>
        <div className={styles.navContainer}>
          <ul className={styles.nav}>
            <li className={styles.navHeader}>
              Contact Us
            </li>
            <li className={styles.navLink}>
                <a href="mailto:team@foundation649.com">team@foundation649.com</a>
            </li>
          </ul>
          <ul className={styles.nav}>
            <li className={styles.navHeader}>
              Stay Connected
            </li>
            <li className={styles.navLink}>
              <Link target="_blank" className={styles.navLink} to="https://www.facebook.com/foundation649/">
                Facebook
              </Link>
            </li>
            <li className={styles.navLink}>
              <Link target="_blank" className={styles.navLink} to="https://www.instagram.com/foundation649_/">
                Instagram
              </Link>
            </li>
          </ul>
          <ul className={styles.nav}>
            <li className={styles.navHeader}>
              Links
            </li>
            <li className={styles.navLink}>
              <Link className={styles.navLink} to="/recipients/">
                Recipients
              </Link>
            </li>
            <li className={styles.navLink}>
              <Link className={styles.navLink} to="/about/">
                About
              </Link>
            </li>
            <li className={styles.navLink}>
              <Link className={styles.navLink} to="/">
                Apply
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
