// extracted by mini-css-extract-plugin
export var footer = "footer-module--footer--675c6";
export var footerWrapper = "footer-module--footerWrapper--4afaa";
export var logo = "footer-module--logo--62df3";
export var logoContainer = "footer-module--logoContainer--ef83c";
export var nav = "footer-module--nav--5749c";
export var navContainer = "footer-module--navContainer--84fd3";
export var navHeader = "footer-module--navHeader--16fd9";
export var navLink = "footer-module--navLink--e952e";
export var social = "footer-module--social--77ebd";
export var socialIcon = "footer-module--socialIcon--8a7aa";
export var socialLink = "footer-module--socialLink--497f2";