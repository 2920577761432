// extracted by mini-css-extract-plugin
export var active = "recipients-module--active--130a7";
export var recipientBox = "recipients-module--recipientBox--442e2";
export var recipientImg = "recipients-module--recipientImg--367a2";
export var recipientName = "recipients-module--recipientName--17444";
export var recipientSchool = "recipients-module--recipientSchool--303e0";
export var recipientTextContainer = "recipients-module--recipientTextContainer--e5b58";
export var recipients = "recipients-module--recipients--76caa";
export var recipientsHero = "recipients-module--recipientsHero--348e3";
export var toggle = "recipients-module--toggle--f9140";
export var yearToggle = "recipients-module--yearToggle--35652";